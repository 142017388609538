<template>
	<div class="models">
		<div class="mcontentwrap px-6 py-4">
			<div class="tabssec">
				<div class="is-flex is-justify-content-space-between is-align-items-center mx-0 mt-3 mb-5">
					<div class="is-size-4 poppins-medium is-capitalized">{{ $route.params.page }}</div>
					<div class="pagination mb-0" v-if="models.length">
						<vue-awesome-paginate
							:total-items="models.length"
							:items-per-page="perPage"
							:max-pages-shown="5"
							v-model="currentPage"
							:hide-prev-next="true"
						>
						</vue-awesome-paginate>
					</div>
				</div>
				<div class="content mt-5" v-if="models.length && $route.params.page !== 'tools'">
					<div class="content poppins-medium">
						To install <strong class="green">Blocks</strong> and <strong class="green">Textures</strong>, drag the files from the .zip
						(not the zip itself) into their respective folders:
						<ul class="mt-1">
							<li>Documents/TrackMania/Blocks/<em>**your Blocks folder**</em></li>
							<li>Documents/TrackMania/Blocks/Data/<em>**your Textures folder**</em></li>
						</ul>
					</div>
				</div>
				<div v-if="!models.length">
					<section class="hero">
						<div class="hero-body">
							<p class="title">Coming soon<sup>TM</sup></p>
						</div>
					</section>
				</div>
				<div class="columns is-multiline">
					<SetCard v-for="(i, j) in modelsPaginated" :key="j" :item="i"></SetCard>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SetCard from '@/components/SetCard.vue';
import db from '@/db.js';

export default {
	components: { SetCard },
	data() {
		return {
			models: [],
			currentPage: 1,
			perPage: 8,
		};
	},
	computed: {
		modelsPaginated() {
			return this.models.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
		},
	},
	methods: {},
	watch: {
		$route(to) {
			this.models = db[to.params.page];
			this.currentPage = 1;
		},
	},
	created() {
		this.models = db[this.$route.params.page];
	},
};
</script>

<style scoped>
.button {
	font-weight: 700;
	background-color: #70cb7c;
	color: #131417;
	box-shadow: 0 2px 10px 0px #00000040;
	border-radius: 3px;
	border-color: #70cb7c;
}
.button:hover {
	background-color: #89ce92;
}
.green {
	color: #89ce92;
}
.content {
	padding: 0.75rem;
	border-radius: 20px;
	background-color: #1e1f25;
}
</style>
